import { useEffect, useState } from 'react';

const useLocalStorage = (key: string, initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const setStoredValue = (newValue: any): void =>
    localStorage.setItem(key, JSON.stringify(newValue));

  useEffect(
    () =>
      setValue(
        JSON.parse(localStorage.getItem(key) ?? JSON.stringify(initialValue)),
      ),
    [],
  );

  return [value, setStoredValue];
};

export default useLocalStorage;
