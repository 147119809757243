import { ComponentType } from 'react';

const withScroller =
  <P extends object>(Component: ComponentType<P>) =>
  (props: any) => {
    const onScroll = (id: string) => {
      const { clientHeight } = document.getElementById('header-nav')!;
      const { offsetLeft, offsetTop } = document.getElementById(id)!;

      window.scrollTo({
        top: offsetTop - clientHeight,
        left: offsetLeft,
        behavior: 'smooth',
      });
    };

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'instant' });

    return (
      <>
        <Component {...props} scrollTo={onScroll} scrollToTop={scrollToTop} />
      </>
    );
  };

export default withScroller;
