const HomeIcon = () => (
  <svg
    className="drop-shadow-4"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      className="drop-shadow-2"
      d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
    />
    <polyline points="9 22 9 12 15 12 15 22" />
  </svg>
);

export default HomeIcon;
