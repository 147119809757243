import Image from 'next/image';
import { useState } from 'react';
import { useRuneContext } from '@/app/contexts';
import withAudioPlayer from '@/app/wrappers/with-audio-player';

type RuneProps = {
  color: string;
  playSound: () => void;
};

const Rune = ({ color, playSound }: RuneProps) => {
  const { runeCollected } = useRuneContext();
  const [animation, setAnimation] = useState(false);
  const [hideRune, setHideRune] = useState(false);

  const onRuneClicked = () => {
    if (hideRune) return;
    playSound();
    setHideRune(true);
    runeCollected(color);
  };

  return (
    <div
      className={`transition-all ease-in duration-1000 ${animation ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`}
      onAnimationStart={() => setAnimation(true)}
    >
      <button
        id={`rune-color-${color}`}
        aria-label={`Rune color: ${color}`}
        className={`w-1/2 md:w-2/3 lg:w-full transition-opacity ease-in duration-400 rune-${color} ${hideRune ? 'opacity-0 cursor-default' : ''}`}
        onClick={onRuneClicked}
      >
        <Image
          src={require(`@/app/assets/images/rune-${color}.webp`)}
          alt={`Rune color: ${color}`}
          decoding="async"
          loading="lazy"
        />
      </button>
    </div>
  );
};

export default withAudioPlayer(Rune, 'rune-collected.mp3');
