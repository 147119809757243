'use client';

import { getAllJobs } from '@/app/api/jobs-api';
import Banner from '@/app/components/banner';
import CareerSection from '@/app/components/career-section';
import Header from '@/app/components/header';
import MainSection from '@/app/components/main-section';
import { GameProvider, RuneProvider } from '@/app/contexts';

export default function Home() {
  return (
    <GameProvider>
      <RuneProvider>
        <Header />
        <Banner />
        <CareerSection />
        <MainSection />
      </RuneProvider>
    </GameProvider>
  );
}
