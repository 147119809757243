import Image from 'next/image';
import { Job } from '@/app/types/api/jobs';

const JobCard = ({ company, backgroundSrc, logoSrc }: Job) => {
  return (
    <div className="p-4 relative uppercase cursor-pointer grid sm:grid-cols-2 sm:grid-rows-2 gap-4 hover:brightness-50">
      <div className="absolute rounded-3xl xl:rounded-4xl inset-0 justify-center">
        <Image
          className="rounded-3xl xl:rounded-4xl w-full h-full object-cover"
          src={backgroundSrc}
          alt={`${company} background`}
          width={600}
          height={120}
          decoding="async"
          loading="lazy"
        />
      </div>
      <div className="absolute rounded-3xl xl:rounded-4xl inset-0 bg-black opacity-50" />
      <h1 className="text-xl sm:text-lg lg:text-2xl xl:text-3xl shadow-2xl z-10 text-shadow-2">
        {company}
      </h1>
      <div className="grid align-middle justify-end w-full">
        <Image
          className="rounded-full drop-shadow-4"
          alt={`${company} logoSrc`}
          src={logoSrc}
          width={32}
          height={32}
          decoding="async"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default JobCard;
