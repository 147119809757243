import { PropsWithChildren } from 'react';

type ButtonProps = PropsWithChildren & {
  id: string;
  'aria-label'?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const Button = (props: ButtonProps) => (
  <button
    {...props}
    className={`text-center px-5 p-3 text-xl tablet:text-3xl rounded-full opacity-90 flex justify-center items-center disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default disabled:before:bg-blend-color-burn transition ease-linear duration-700 ${props.className}`}
  >
    {props.children}
  </button>
);

export default Button;
