import { ComponentType } from 'react';

const withAudioPlayer =
  <P extends object>(Component: ComponentType<P>, audioSrc: string) =>
  (props: any) => {
    const audio = new Audio(`/sounds/${audioSrc}`);

    return <Component {...props} playSound={() => audio.play()} />;
  };

export default withAudioPlayer;
