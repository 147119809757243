const pages = [
  {
    name: 'Career Odyssey',
    id: 'career-section',
  },
  {
    name: 'Learning Journey',
    id: 'learning-section',
  },
  {
    name: 'Innovations',
    id: 'innovations-section',
  },
];

export default pages;
