import type { PropsWithChildren } from 'react';
import { createContext, useContext, useLayoutEffect, useState } from 'react';
import AllRunes from '@/app/constants/runes';
import useLocalStorage from '@/app/hooks/use-localstorage';

type RuneContextType = {
  availableRunes: string[];
  collectedRunes: string[];
  runeCollected: (rune: string) => void;
};

const RuneContext = createContext({
  availableRunes: [],
  collectedRunes: [],
  runeCollected: () => {},
} as RuneContextType);

const { Provider } = RuneContext;

export const RuneProvider = ({ children }: PropsWithChildren) => {
  const [value, setValue] = useLocalStorage('collectedRunes', []);
  const [availableRunes, setAvailableRunes] = useState<string[]>([]);
  const [collectedRunes, setCollectedRunes] = useState<string[]>(value);

  const runeCollected = (rune: string) => {
    const runes = [...collectedRunes, rune];
    setValue(runes);
    setCollectedRunes(runes);
  };

  useLayoutEffect(() => {
    setCollectedRunes(value);
    setAvailableRunes(AllRunes.filter((rune) => !value.includes(rune)));
  }, [value]);

  return (
    <Provider value={{ availableRunes, collectedRunes, runeCollected }}>
      {children}
    </Provider>
  );
};

export const useRuneContext = () => {
  const context = useContext(RuneContext);

  if (!context) {
    throw new Error('useRuneContext must be used within a RuneProvider');
  }

  return context;
};
