import Image from 'next/image';
import BarsIcon from '@/app/assets/icons/BarsIcon';
import RuneIcon from '@/app/assets/icons/RuneIcon';
import Button from '@/app/atoms/Button';
import pages from '@/app/constants/pages';
import { useGameContext, useRuneContext } from '@/app/contexts';
import { getCloudinaryImage } from '@/app/helpers/get-cloudinary-image';
import withScroller from '@/app/wrappers/with-scroller';

type HeaderProps = {
  scrollTo: (id: string) => void;
};

const Header = ({ scrollTo }: HeaderProps) => {
  const { collectedRunes } = useRuneContext();
  const { isPlaying } = useGameContext();

  return (
    <header className="sticky top-0 z-40">
      <div
        id="header-nav"
        className={`absolute my-8 px-4 w-full flex items-center gap-4 justify-between ${isPlaying ? 'hidden' : ''}`}
      >
        <div>
          <figure className="h-16 w-16">
            <Image
              className="rounded-full"
              src={getCloudinaryImage('avatar')}
              width={128}
              height={128}
              alt="Ale's Avatar"
              decoding="async"
              loading="lazy"
            />
          </figure>
        </div>
        <div className="hidden lg:block leading-none text-2xl xl:text-3xl text-white drop-shadow-lg">
          <div className="w-0 h-0 opacity-0 lg:opacity-100 lg:w-auto lg:h-auto transition-all">
            Alejandro <span className="text-orange-500">Tidele</span>
          </div>
        </div>
        <nav className="hidden sm:flex py-1.5 rounded-full ml-auto text-xs uppercase pointer-events-auto bg-orange-400 text-white">
          <ul className="flex gap-2 h-full my-auto mx-3 align-middle items-center">
            {pages.map(({ name, id }) => (
              <li
                key={id}
                role="button"
                className="rounded-full text-xs tablet:text-sm lg:text-base xl:text-lg hover:bg-orange-300 hover:text-white cursor-pointer xs:px-2 md:px-4 py-1 tracking-wider"
                onClick={() => scrollTo(id)}
              >
                {name}
              </li>
            ))}
          </ul>
        </nav>
        <div className="flex flex-shrink-0 pointer-events-auto mx-6 sm:mx-0">
          <Button
            id="rune-viewer-button"
            disabled={collectedRunes.length === 0}
            className="hidden sm:flex justify-center xs:px-3 lg:px-4 py-2.5 tracking-wider text-xs tablet:text-sm lg:text-base xl:text-lg uppercase bg-orange-400 text-white hover:bg-orange-300 hover:text-white"
          >
            Rune Viewer
          </Button>
        </div>
        <div className="sm:hidden flex gap-4">
          <Button
            id="rune-icon-button"
            aria-label="Open Rune viewer"
            disabled={collectedRunes.length === 0}
            className="w-16 h-16 px-0 bg-orange-400 text-white"
          >
            <RuneIcon />
          </Button>
          <Button
            id="menu-icon-button"
            aria-label="Open Menu"
            className="w-16 h-16 px-0 bg-orange-400 text-white"
          >
            <BarsIcon />
          </Button>
        </div>
      </div>
    </header>
  );
};

export default withScroller(Header);
